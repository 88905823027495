import React, { useEffect, useState } from "react";
import Select from "react-select";
import ClipLoader from "react-spinners/ClipLoader";

import Card from "../../../components/Cards";
import Layout from "../../../components/Layout";
import SEO from "../../../components/SEO";
import TitleC6 from "../../../components/Typography/TitleC6";

import requests from "../../../services/requests";

const IndexPage = () => {
    const [clients, setClients] = useState([]);
    const [clientsOptions, setClientsOptions] = useState([]);
    const [currentClient, setCurrentClient] = useState(null);
    const [isLoadingClients, setIsLoadingClients] = useState(false);

    const getClients = () => {
        setIsLoadingClients(true);
        requests.listClientsWithPowerBiUrl().then(list => {
            var aux = [];
            for (let i in list) {
                aux.push({
                    value: list[i].identifier,
                    label: list[i].name + " - " + list[i].documentNumberFormatted,
                });
            }

            setClients(list);
            setClientsOptions(aux);

            if (list.length === 1) {
                setCurrentClient(list[0]);
            }

            setIsLoadingClients(false);
        });
    };

    const onChangeSetCurrentClient = item => {
        if (item && item.value) {
            for (let i in clients) {
                if (clients[i].identifier === item.value) {
                    setCurrentClient(clients[i]);
                }
            }
        }
    };

    useEffect(() => {
        getClients();
    }, []);

    return (
        <Layout>
            <SEO title="Armazém" />
            <div className="p-4">
                <Card>
                    <TitleC6>Armazém</TitleC6>

                    {isLoadingClients ? (
                        <div className="flex flex-col items-center mt-5">
                            <ClipLoader size={40} loading={true} />
                        </div>
                    ) : (
                        <>
                            {clientsOptions.length > 1 && (
                                <>
                                    <Select
                                        options={clientsOptions}
                                        id="client"
                                        name="client"
                                        className="outline-none transform-none"
                                        placeholder="Selecione uma empresa"
                                        onChange={item => onChangeSetCurrentClient(item)}
                                        isClearable
                                    />
                                </>
                            )}

                            {clients.length === 0 && (
                                <div className="flex flex-col items-center mt-5">Sem dados para apresentar</div>
                            )}

                            {currentClient && (
                                <>
                                    {clients.length > 1 && (
                                        <h5>
                                            Dados de {currentClient.name}{" "}
                                            <small>({currentClient.documentNumberFormatted})</small>
                                        </h5>
                                    )}
                                    <div className="pt-4 pb-12 overflow-hidden">
                                        <iframe
                                            width="100%"
                                            height="800px"
                                            src={currentClient.powerbiUrl}
                                            frameBorder="0"
                                            allowFullScreen={true}></iframe>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                </Card>
            </div>
        </Layout>
    );
};

export default IndexPage;
